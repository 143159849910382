<template>
  <div class='justify-content-start view-container' @click='closeContextMenu'>
    <HeaderResidential />
    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px;">
      <h1 style="margin: 0;">{{$t('digitalLibrary.digitalLibrary')}}</h1>
      <div class="ui icon input">
          <input
            type="text"
            v-model="searchQuery"
            @input="performSearch"
            :placeholder="$t('digitalLibrary.searchFile')"
          />
          <i class="search icon"></i>
        </div>
    </div>
    <div class='mt-4 file-manager-container' @click='closeContextMenu'>
      <div
        ref="fileManager"
        class='file-manager mt-4'
        @dragover.prevent="dragOverFile"
        @drop='dropFile'
        @dragenter="dragEnterFile"
        @dragleave="dragLeaveFile"
      >
        <div class="fm-container">
          <!-- go back and current folder -->
          <div>
            <i
                class='icon arrow left mb-3 go-back-icon'
                @click='goBack'
                v-if='currentFolder !== null'
            >
            </i>
            <span class='folder-name' v-if='currentFolder !== null'>
              {{ currentFolder }}
            </span>
          </div>
          <!-- list of items -->
          <table class="table-base">
            <thead style="width: 100%;">
              <tr style="width: 100%;">
                <th class="name-col name-header">{{$t('digitalLibrary.name')}}</th>
                <th class="size-col size-header">{{$t('digitalLibrary.size')}}</th>
                <th class="modified-col modified-header">{{$t('digitalLibrary.modified')}}</th>
                <th class="action-col action-header"></th>
              </tr>
            </thead>
          </table>
          <table class="table-base mt-2" v-if="filesAndFoldersToShowObjEntriesWIdx.length > 0">
              <tbody>
                <tr
                  class='dl-item'
                  v-for='[idx, [name, item]] in filesAndFoldersToShowObjEntriesWIdx' :key="idx"
                  :class="[
                    { 'dl-folder': item.type === 'folder' },
                    { 'dl-file': item.type === 'file' },
                    { 'dl-bottom-line': idx < filesAndFoldersToShowObjEntriesWIdx.length - 1 },
                    { 'dl-top-line': idx === 0}
                  ]"
                  @dragenter="dragEnterFile"
                  @dragleave="dragLeaveFile"
                >
                  <td
                    class="name-col"
                    :style="
                      { cursor: item.type === 'folder' ? 'pointer' : 'default' }
                    "
                    @click='item.type === "folder" ? navigateFolder(name) : null'
                    @contextmenu.prevent="item.type === 'file' ? openContextMenu($event, name, item) : null"
                  >
                    <span
                      :class="[
                        { 'folder-container-row': item.type === 'folder' },
                        { 'file-container-row': item.type === 'file' }]"
                      >
                      <i
                        class='icon'
                        :class="[
                          { 'folder dl-folder-icon': item.type === 'folder' && !item.default },
                          { 'folder dl-folder-icon-default': item.type === 'folder' && item.default },
                          { 'file dl-file-icon': item.type === 'file' }]"
                      ></i>
                      {{ name }}
                    </span>
                  </td>
                  <td
                    class="size-col"
                  >{{ item.type === 'folder' ? '-' : humanReadableSize(item.size) }}</td>
                  <td
                    class="modified-col"
                  >{{ item.updatedAt ? getFormattedModified(item.updatedAt) : '-' }}</td>
                  <td
                    class="action-col"
                    :style="{visibility: item.type === 'file' ? 'inherit' : 'hidden'}"
                  >
                    <i class='icon download' title="Descargar" @click='downloadFile(name, item)'></i>
                    <i class='icon linkify' title="Copiar enlace" @click='copyLink(item)'></i>
                    <i class='icon trash' title="Eliminar" @click='deleteFile(name, item)'></i>
                  </td>
                </tr>
              </tbody>
            </table>
          <!-- in case of empty folder -->
          <div v-if="filesAndFoldersToShowObjEntriesWIdx.length === 0 && !isGettingFiles" class="empty-folder dl-top-line mt-2">
            <div>
              {{ searchQuery ? $t('digitalLibrary.noSearchResults') : $t('digitalLibrary.emptyFolder') }}
            </div>
          </div>
        </div>
      </div>
      <div class='upload-btn-container mt-4'>
        <Button
          :text="$t('digitalLibrary.uploadFile')"
          color='primary'
          class='d-inline'
          :class="{ disabled: currentFolder === null }"
          @click='$refs.fileUpload.click()'
        />
        <input
          type='file'
          ref='fileUpload'
          @change='uploadFileFromBtn'
          style='display: none'
          multiple
        />
      </div>
    </div>
  </div>
  <!-- <div v-if="uploadingFiles.length > 0" class="upload-loader">
    <div
      v-for="fileUpload in uploadingFiles"
      :key="fileUpload.file.name"
      class="upload-item"
    >
      <div class="loader"></div>
      <span class="file-name">{{ getTruncatedString(fileUpload.file.name, 100) }}</span>
    </div>
  </div> -->
  <template v-if="contextMenu.active">
  <div
    class="context-menu"
    :style="{ top: contextMenu.y + 'px', left: contextMenu.x + 'px' }"
    @click.stop
  >
    <i
        class='icon download'
        :title="$t('buttons.download')"
        @click='downloadFile(contextMenu.fileName, contextMenu.fileInfo)'
    >
    </i>
    <i
        class='icon linkify'
         :title="$t('buttons.copyLink')"
        @click='copyLink(contextMenu.fileInfo)'
    >
    </i>
    <i
        class='icon trash'
        :title="$t('buttons.delete')"
        @click='deleteFile(contextMenu.fileName, contextMenu.fileInfo)'
    >
    </i>
  </div>
</template>

</template>

<script>
import HeaderResidential from '@/components/ui/HeaderResidential'
import Button from '../../components/form/Button'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DigitalLibrary',
  components: {
    HeaderResidential,
    Button
  },
  data () {
    return {
      dragCounter: 0,
      filesAndFolders: {},
      currentFolder: null,
      folderStack: [],
      contextMenu: {
        active: false,
        x: 0,
        y: 0,
        fileName: null,
        fileInfo: null
      },
      uploadingFile: null,
      isGettingFiles: false,
      uploadingFiles: [],
      searchQuery: ''
    }
  },
  mounted () {
    this.isGettingFiles = true
    this.setLoading(true)
    this.loadResDigitalLibrary().then(() => {
      this.filesAndFolders = this.$store.getters['digitalLibrary/activeResidentialDigitalLibrary']
      if (
        this.$store.getters['digitalLibrary/routeToNavigate'] !== null &&
        ['/spacesManagement'].includes(this.fromRoute)
      ) {
        this.currentFolder = this.$store.getters['digitalLibrary/routeToNavigate']
        this.folderStack.push(this.filesAndFolders)
      } else {
        this.currentFolder = null
        this.folderStack = []
      }
      // console.log('filesAndFolders', this.filesAndFolders)
      // console.log('routeToNavigate from DL', this.$store.getters['digitalLibrary/routeToNavigate'])
      this.isGettingFiles = false
      this.setLoading(false)
    })
  },
  beforeUnmount () {
    // console.log('beforeUnmount')
    this.$store.commit('digitalLibrary/setRouteToNavigate', null)
    this.currentFolder = null
  },
  computed: {
    activeResidential () {
      return this.$store.getters['residential/activeResidential']
    },
    ...mapGetters('socialAreas', [
      'allAreas'
    ]),
    ...mapGetters('routes', ['fromRoute', 'toRoute']),
    // filesAndFoldersToShow () {
    //   if (this.currentFolder !== null) {
    //     return this.filesAndFolders[this.currentFolder].children
    //   } else {
    //     return this.filesAndFolders
    //   }
    // },
    // new
    filesAndFoldersToShow () {
      if (this.currentFolder !== null) {
        const folderItems = this.filesAndFolders[this.currentFolder].children
        return Object.entries(folderItems)
          .filter(([name]) => name.toLowerCase().includes(this.searchQuery.toLowerCase()))
          .reduce((filteredItems, [name, item]) => {
            filteredItems[name] = item
            return filteredItems
          }, {})
      } else {
        return Object.entries(this.filesAndFolders)
          .filter(([name]) => name.toLowerCase().includes(this.searchQuery.toLowerCase()))
          .reduce((filteredItems, [name, item]) => {
            filteredItems[name] = item
            return filteredItems
          }, {})
      }
    },
    // new
    filesAndFoldersToShowObjEntriesWIdx () {
      return Object.entries(this.filesAndFoldersToShow).map(([name, item], idx) => [
        idx,
        [name, item]
      ])
    }
  },
  methods: {
    performSearch () {
      // You may add additional criteria for size and updatedAt searches in the future
      // Currently, it only searches for files and folders by name
      // For now, it just triggers the computed property recalculation based on the searchQuery change
    },
    getTruncatedString (string, maxLength) {
      if (string.length > maxLength) {
        return string.substring(0, maxLength) + '...'
      } else {
        return string
      }
    },
    getFormattedModified (inputString) {
      const date = new Date(inputString.replace('Z', ''))
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')
      const second = date.getSeconds().toString().padStart(2, '0')
      return `${day}/${month}/${year}, ${hour}:${minute}:${second}`
    },
    humanReadableSize (sizeBytes) {
      const suffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      // Handle the case when the size is 0 or negative
      if (sizeBytes <= 0) {
        return '0 B'
      }

      // Calculate the appropriate suffix index and size value
      const index = Math.min(suffixes.length - 1, Math.floor(Math.log(sizeBytes) / Math.log(1024)))
      const size = sizeBytes / Math.pow(1024, index)

      // Format the result with up to two decimal places
      return `${size.toFixed(2)} ${suffixes[index]}`
    },
    dragOverFile (event) {
      if (this.currentFolder !== null) {
        event.preventDefault()
      }
    },

    // Method to handle the dragenter event
    dragEnterFile (event) {
      if (this.currentFolder !== null) {
        this.addHoverClass()
      }
    },

    // Method to handle the dragleave event
    dragLeaveFile (event) {
      if (this.currentFolder !== null) {
        this.removeHoverClass()
      }
    },
    ...mapActions('digitalLibrary', [
      'loadRootDigitalLibraryOfResidential',
      'uploadFileInFolder',
      'deleteFileInFolder',
      'downloadFileFromBackend'
    ]),
    ...mapActions('loading', ['setLoading']),
    loadResDigitalLibrary () {
      return this.loadRootDigitalLibraryOfResidential(this.activeResidential.residentialId)
    },
    downloadFile (fileName, fileInfo) {
      this.closeContextMenu()
      this.$swal({
        title: this.$t('digitalLibrary.downloadStarting'),
        text: `${this.$t('digitalLibrary.theFile')} ${this.getTruncatedString(fileName, 100)} ${this.$t('digitalLibrary.willDownload')}`,
        icon: 'success',
        confirmButtonColor: '#28a745',
        confirmButtonText: this.$t('ok')
      })
      this.downloadFileFromBackend({
        fileId: fileInfo.id
      }).then((res) => {
        window.open(res.data.url, '_blank')
      }
      )
    },
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$swal({
          title: this.$t('digitalLibrary.linkCopied'),
          text: this.$t('digitalLibrary.linkCopiedMessage'),
          icon: 'success',
          confirmButtonColor: '#28a745',
          confirmButtonText: this.$t('ok')
        })
      } catch (err) {
        this.$swal({
          title: this.$t('digitalLibrary.errorCopiedLink'),
          text: this.$t('digitalLibrary.errorCopiedLinkMessage'),
          icon: 'error',
          confirmButtonColor: '#dc3545',
          confirmButtonText: this.$t('ok')
        })
      }
    },
    async copyLink (fileInfo) {
      this.closeContextMenu()
      this.downloadFileFromBackend({
        fileId: fileInfo.id
      }).then((res) => {
        this.copyToClipboard(res.data.url)
      })
    },
    deleteFile (fileName, fileInfo) {
      this.$swal({
        title: this.$t('digitalLibrary.deleteFile'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal('', this.$t('digitalLibrary.fileDeleted'), 'info')
          this.$swal.showLoading()
          this.deleteFileInFolder({
            folderId: this.filesAndFolders[this.currentFolder].id,
            fileId: fileInfo.id
          }).then((e) => {
            this.updateFolderDate()
            this.$swal.close()
            this.$swal('', this.$t('digitalLibrary.fileDeleted2'), 'success')
            delete this.filesAndFolders[this.currentFolder].children[fileName]
          })
        }
        this.closeContextMenu()
      })
    },

    updateFolderDate () {
      const date = new Date()
      const isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
      this.filesAndFolders[this.currentFolder].updatedAt = isoDateTime
    },
    openContextMenu (event, fileName, fileInfo) {
      this.contextMenu.active = true
      this.contextMenu.x = event.clientX
      this.contextMenu.y = event.clientY
      this.contextMenu.fileName = fileName
      this.contextMenu.fileInfo = fileInfo
    },
    closeContextMenu () {
      this.contextMenu.active = false
    },
    addHoverClass () {
      this.dragCounter++
      if (this.dragCounter === 1) {
        const fileManager = this.$refs.fileManager
        fileManager.classList.add('hover')
      }
    },
    removeHoverClass () {
      this.dragCounter--
      if (this.dragCounter === 0) {
        const fileManager = this.$refs.fileManager
        fileManager.classList.remove('hover')
      }
    },

    async sendFilesToBackend (files, targetFilesAndFolders) {
      try {
        const sendFilesPromises = []
        let filesName = ''
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const formData = new FormData()
          formData.append('file', file)
          this.uploadingFile = file
          filesName = (i === files.length - 1) ? filesName + `${file.name}.` : filesName + `${file.name},\n`
          this.uploadingFiles.push({
            file
          })
          sendFilesPromises.push(this.sendSingleFileToBackend(formData))
        }
        this.$swal('', `${filesName}`, 'info')
        this.$swal.showLoading()
        const failedFiles = []
        await Promise.allSettled(sendFilesPromises).then(
          (filesUploaded) => {
            filesUploaded.forEach((filePromResp, idx) => {
              if (filePromResp.status === 'fulfilled') {
                targetFilesAndFolders[filePromResp.value.name] = {
                  type: 'file',
                  id: filePromResp.value.id,
                  mimeType: filePromResp.value.mimeType,
                  size: filePromResp.value.size,
                  updatedAt: filePromResp.value.updatedAt
                }
              } else {
                failedFiles.push({
                  file: filePromResp.reason.config.data.get('file'),
                  reasonMessage: filePromResp.reason.response.data.message
                })
              }
            })
            this.updateFolderDate()
            this.$swal.close()
            this.$swal('', this.$t('digitalLibrary.uploadedFiles'), 'success')
            this.uploadingFiles = []
          }
        )
        if (failedFiles.length > 0) {
          let failedFilesList = '<ul style="max-height: 150px; overflow-y: auto; padding: 0;">'
          failedFiles.forEach(({ file, reasonMessage }) => {
            failedFilesList += `<li style="padding: 5px 0;"> ${this.$t('digitalLibrary.file')}: ${this.getTruncatedString(file.name, 100)}.<br/><br/>${this.$t('digitalLibrary.reason')}: ${reasonMessage}</li>`
          })
          failedFilesList += '</ul>'
          this.$swal.close()
          this.$swal({
            title: this.$t('digitalLibrary.errorUploading'),
            html: `${this.$t('digitalLibrary.errorUploadingMessage')} ${failedFilesList}`,
            confirmButtonColor: '#dc3545',
            confirmButtonText: this.$t('ok')
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async uploadFileFromBtn (e) {
      try {
        this.$swal('', this.$t('digitalLibrary.uploadingFiles'), 'info')
        this.$swal.showLoading()
        const files = e.target.files
        const targetFilesAndFolders = this.filesAndFolders[this.currentFolder].children

        const areDuplicateFiles = this.checkDuplicateFiles(files, targetFilesAndFolders)

        if (areDuplicateFiles) {
          e.target.value = null
          return
        }
        await this.sendFilesToBackend(files, targetFilesAndFolders)
      } catch (error) {
        this.$swal.close()
        this.$swal({
          title: this.$t('digitalLibrary.errorUploading'),
          text: this.$t('digitalLibrary.errorUploadingMessage'),
          confirmButtonColor: '#dc3545',
          confirmButtonText: this.$t('ok')
        })
      }
      e.target.value = null
    },

    // Function to simulate file upload delay
    sendSingleFileToBackend (file) {
      return this.uploadFileInFolder({
        folderId: this.filesAndFolders[this.currentFolder].id,
        file
      })
    },

    navigateFolder (fName) {
      this.folderStack.push(this.filesAndFolders)
      this.currentFolder = fName
      if (this.searchQuery !== '') {
        this.searchQuery = ''
      }
    },
    goBack () {
      this.filesAndFolders = this.folderStack.pop()
      this.currentFolder = null
    },
    removeDragCounter () {
      while (this.dragCounter > 0) {
        this.removeHoverClass()
      }
    },
    checkDuplicateFiles (filesToUpload, filesInFolder) {
      const duplicateFiles = []
      let duplicateFilesList = '<ul style="max-height: 150px; overflow-y: auto; padding: 0;">'
      for (const file of filesToUpload) {
        if (filesInFolder[file.name]) {
          duplicateFiles.push(file.name)
          duplicateFilesList += `<li style="padding: 5px 0;">${file.name}</li>`
        }
      }
      duplicateFilesList += '</ul>'
      const areDuplicateFiles = duplicateFiles.length > 0
      if (areDuplicateFiles) {
        this.$swal.close()
        this.$swal({
          title: this.$t('digitalLibrary.duplicate'),
          html: `${this.$t('digitalLibrary.duplicateMessage')} ${duplicateFilesList}${this.$t('digitalLibrary.duplicateMessage2')}`,
          confirmButtonColor: '#dc3545',
          confirmButtonText: this.$t('ok')
        })
      }
      return areDuplicateFiles
    },
    async dropFile (e) {
      try {
        e.preventDefault()
        if (this.currentFolder === null) {
          this.$swal({
            title: this.$t('digitalLibrary.error'),
            text: this.$t('digitalLibrary.folderError'),
            confirmButtonColor: '#dc3545',
            confirmButtonText: this.$t('ok')
          })
          this.removeDragCounter()
          return
        }
        const files = e.dataTransfer.files

        const targetFilesAndFolders = this.filesAndFolders[this.currentFolder].children

        const areDuplicateFiles = this.checkDuplicateFiles(files, targetFilesAndFolders)

        if (areDuplicateFiles) {
          this.removeDragCounter()
          return
        }

        await this.sendFilesToBackend(files, targetFilesAndFolders)
        this.removeDragCounter()
      } catch (error) {
        this.$swal({
          title: this.$t('digitalLibrary.errorUploading'),
          text: this.$t('digitalLibrary.errorUploadingMessage'),
          confirmButtonColor: '#dc3545',
          confirmButtonText: this.$t('ok')
        })
      }
    }
  }
}
</script>

<style scoped>
.file-manager-container {
  padding-right: 1rem
}

.file-manager {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.file-manager.hover {
  border: 2px dashed #007BFF;
  background-color: rgba(0,123,255,0.1); /* light blue with 10% opacity */
}

.dl-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  /* border-bottom: 1px solid #ccc; */
  height: 100%;
}

.dl-folder {
    width: 100%;
    height: 100%;
    /* cursor: pointer; */
    transition: background-color 0.1s ease;
}

.dl-bottom-line {
  border-bottom: 1px solid #ccc;
}

.dl-top-line {
  border-top: 1px solid #e2dddd;
}

.dl-folder:hover {
  background-color: rgba(0, 0, 0, 0.1); /* A bit opaque on hover */
}

.dl-folder-icon {
  color: rgb(201, 196, 44);
}

.dl-folder-icon-default {
  color: var(--primary-color)
}

.dl-file-icon {
    color: var(--primary-color)
}

.go-back-icon {
    /* font-size: 1.5rem; */
    /* margin-right: 1rem; */
    cursor: pointer;
    color: var(--primary-color)
    /* style='cursor: pointer; color: vars(--primary-color)' */
}

.context-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
}

.icon.download {
  color: #2185d0;
  cursor: pointer;
}

.icon.linkify {
  color: #2185d0;
  cursor: pointer;
}

.icon.trash {
  color: #eb2424;
  cursor: pointer;
}

.file-container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.folder-container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.file-info {
  width: 90%;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.upload-loader {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.file-name {
  margin-left: 10px;
  font-weight: bold;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.upload-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.table-base {
  width: 100%;
  border-collapse: collapse;
}

.name-col {
  width: 64%;
}
/* .size-col, .modified-col, .action-col {
  width: 12%;
} */
.size-col, .action-col {
  width: 10%;
}
.modified-col {
  width: 16%;
}
.size-col, .modified-col {
  padding-left: 1rem;
}

.action-col {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.name-header, .size-header {
  border-right: 1px solid #e2dddd;
}

.empty-folder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  /* height: 100%; */
  /* font-size: 1.2rem; */
  /* color: #ccc; */
}

</style>
